import React from "react";
import Layout from "../components/Layout";
import RightColumn from "../components/RightColumn";

export default class TermsAndConditionsPage extends React.Component {
  render() {
    return (
      <Layout sidebarImageUrl="/images/photo12.jpg" sidebarImageAlt="" metaTitle="Terms and Conditions">
        <div id="mainContent">
          <div class="header-one">
            ANALYTICAL SERVICES
            <br />
            STANDARD TERMS &amp; CONDITIONS
          </div>
          <p>
            All analytical services provided by WBA Analytical Laboratories,
            Inc. (&ldquo;WBAAL&rdquo;) are subject to the terms and conditions
            stated herein. As our client, you (&ldquo;Client&rdquo;) understand
            and agree that submission of a Sample Submission Form
            (&ldquo;SSF&rdquo;) or substitute to WBAAL constitutes acceptance of
            the terms and conditions as stated herein. To the extent that any
            Client order contains any terms or conditions that vary from the
            terms and conditions stated herein, all such additional or varying
            terms and conditions shall be of no force or effect and shall not be
            part of the Client/WBAAL relationship or contract, even if WBAAL
            performs the service requested.{" "}
          </p>
          <p>
            <strong>CONFIDENTIALITY: </strong>Confidentiality is maintained by
            WBAAL in all interactions with Clients. Each party shall protect
            Information of the other party, using the same degree of care, but
            no less than a reasonable degree of care, as such party uses to
            protect its own confidential information. Each party shall disclose
            the other's Information only to its employees or contractors who
            have a need to know and are bound by obligations of
            confidentiality.&nbsp; Mutual nondisclosure agreements will be
            executed upon request. The Client agrees it will not use
            WBAAL&rsquo;s name or data (or the name or data of any Tyson Foods
            entity) in any manner which might cause harm to WBAAL&rsquo;s (or
            Tyson Foods&rsquo;) reputation and/or business. Under no
            circumstances is the name of WBAAL (or of any of its Tyson Foods
            affiliates) to be published, either alone or in association with
            that of any other party, without prior written approval.
          </p>
          <p>
            <strong>SAMPLE SUBMISSION AND RETENTION: </strong>Samples should be
            submitted to WBAAL with a completed SSF and shipped to WBAAL in
            accordance with the instructions on the SSF. Chemistry samples will
            be held for two (2) weeks from the date of the final report, and
            microbiology samples will be held for one (1) week from the date of
            the final report, except (1) where samples have been used for
            testing and fully consumed; (2) where micro samples are destroyed
            upon completion of testing; (3) where suspect samples have been
            autoclaved; (4) where samples are returned early at the request of
            the Client; (5) where storage for the stated amount of time will
            compromise the integrity of the sample; or (6) where circumstances
            exist that prevent samples from being held for the stated amount of
            time. The Client shall reimburse WBAAL for the cost of returning
            samples.{" "}
          </p>
          <p>
            <strong>RETEST ANALYSES: </strong>Retests performed at the
            Client&rsquo;s request may be charged to the Client if the retest
            confirms the original result. If the retest results are
            significantly different from the original result, the Client will
            not be charged for the retest.{" "}
          </p>
          <p>
            <strong>HAZARDOUS SUBSTANCES AND PATHOGENS: </strong>Any package
            containing a sample containing or suspected of containing a pathogen
            or hazardous substance must be clearly identified as such and
            communicated to WBAAL prior to shipping. WBAAL reserves the right to
            refuse any shipment or sample that may pose a risk to its team
            members. The Client shall bear all extraordinary costs for adequate
            disposal of hazardous waste resulting from the sample(s) submitted,
            whether or not described as hazardous waste.{" "}
          </p>
          <p>
            <strong>BILLING: </strong>All fees are charged directly to the
            Client as indicated under Invoice Information on the SSF, unless a
            third party acceptable to our credit department has been authorized
            via a signed statement indicating payment responsibility. It is
            assumed that the paperwork submitted with a sample describes the
            testing desired. If changes are made to the work order or to the
            party responsible for payment after the originally requested testing
            is initiated or completed, the Client must accept payment
            responsibility. Please notify WBAAL immediately if changes in
            testing are necessary.{" "}
          </p>
          <p>
            <strong>PAYMENT TERMS: </strong>Prepayment or credit approval is
            required to establish a new account. Payment terms are net 15 days
            of invoice date. A 1&frac12; % per month late charge may be added to
            all unpaid balances. Delinquent accounts may be served on a C.O.D.
            basis. &nbsp;All prices are subject to change without notice.
          </p>
          <p>
            <strong>LITIGATION ASSISTANCE: </strong>If Client information is
            subpoenaed in response to any court of law or regulatory body having
            jurisdiction, WBAAL will make best efforts to notify the Client in
            advance of such subpoena deadline and allow Client to take
            appropriate steps to protect its information. All costs associated
            with litigation or dispute, including copying and submission of all
            documents subpoenaed, for oral or written testimony or preparation
            of same, or for any other purpose related to work provided by WBAAL
            in connection with the analyses/reports performed/completed for the
            Client, shall be paid by the Client. Such costs include, but are not
            limited to, hourly charges, travel, accommodations, mileage, counsel
            and all other expenses associated with said litigation or dispute.{" "}
          </p>
          <p>
            <strong>WARRANTY AND LIMITS OF LIABILITY: </strong>WBAAL warrants
            that all services will be performed in a timely manner by competent
            personnel. THIS EXPRESS WARRANTY IS EXCLUSIVE AND IS GIVEN IN LIEU
            OF ALL OTHER WARRANTIES, EXPRESSED OR IMPLIED. WE DISCLAIM ANY OTHER
            WARRANTIES EXPRESSED OR IMPLIED, INCLUDING A WARRANTY OF FITNESS FOR
            PARTICULAR PURPOSE AND WARRANTY OF MERCHANTABILITY. Any services
            performed by WBAAL which are reasonably determined by Client to have
            been performed improperly in light of the above warranty, and which
            after investigation by WBAAL are acknowledged in writing by
            WBAAL&rsquo;s Vice President to have been performed improperly,
            shall be corrected by WBAAL without charge to Client, provided that
            Client provides WBAAL with a written request for such correction
            within two (2) months after Client knew or should reasonably have
            known of the problem. In no event will WBAAL (including its parent
            and affiliates, i.e., all entities owned, controlled, or under
            common control with, Tyson Foods, Inc. (collectively, &ldquo;Tyson
            Foods&rdquo;)) be liable for any indirect, consequential,
            incidental, punitive, or special damages or for acts of negligence
            that are unintentional in nature, including, without limitation,
            damages for lost profit, business interruption, or other pecuniary
            loss, regardless of whether WBAAL has been advised of the
            possibility of such damages. Client further understands and agrees
            that WBAAL&rsquo;s total liability (including any liability of Tyson
            Foods) for damages relating to, or arising in connection with, any
            services provided hereunder, regardless of the form of action or
            theory of recovery shall not exceed the greater of $2,500 or the
            amount paid by the Client for the particular analytical service.
            WBAAL and Tyson Foods accept no legal responsibility for the purpose
            for which the Client uses the test results or reports, or for any
            consequence of such use. Client agrees to indemnify and defend WBAAL
            and Tyson Foods from all claims, damages, liabilities, and expenses
            relating to Client&rsquo;s use of WBAAL&rsquo;s services or
            Client&rsquo;s marketing, distribution, sale, or other dissemination
            of Client&rsquo;s products or services. The allocations of liability
            in this WARRANTY AND LIMITS OF LIABILITY section represent the
            agreed and bargained-for understanding between the Client and WBAAL.
            WBAAL&rsquo;s fees for the services provided hereunder reflect such
            allocations.
          </p>
          <p>
            <strong>Choice of Law and Venue</strong>. These terms and conditions
            shall be interpreted pursuant to the laws of the State of Arkansas
            without giving effect to its choice of law provisions. Litigation
            brought to contest disputes arising under this purchase order shall
            be brought only in the state or federal courts of the State of
            Arkansas.{" "}
          </p>
          <ol>
            <li>
              Waiver of Jury Trial. WBAAL AND CLIENT IRREVOCABLY WAIVE THEIR
              RESPECTIVE RIGHTS TO TRIAL BY JURY OF ANY CAUSE OF ACTION, CLAIM,
              COUNTERCLAIM OR CROSS-COMPLAINT IN ANY ACTION OR OTHER PROCEEDING
              BROUGHT BY THE OTHER WITH RESPECT TO ANY MATTER ARISING OUT OF, OR
              IN ANY WAY CONNECTED WITH OR RELATED TO WBAAL SERVICE TO CLIENT,
              WHETHER BASED UPON CONTRACTUAL, STATUTORY, TORTIOUS OR OTHER
              THEORIES OF LIABILITY.&nbsp;{" "}
            </li>
          </ol>
          <p>
            <strong>INTELLECTUAL PROPERTY</strong>: WBAAL operates under
            &ldquo;need to know&rdquo; principles within its organization to
            further protect its clients&rsquo; information. WBAAL reserves the
            right to decline service to a client if the nature of the work
            presents a potential conflict of interest with other work either
            currently underway or previously completed for another party. WBAAL
            also operates under the principle that the outcome of its work is
            owned by the receiving client unless previously agreed to in writing
            between the parties.{" "}
          </p>
          <p>
            <strong>FORCE MAJEURE: </strong>Neither party shall be liable for
            damages due to delay or failure to perform any obligation under this
            Agreement to the extent such delay or failure results directly or
            indirectly from circumstances beyond the control of such party. Such
            circumstances shall include, but shall not be limited to, acts of
            God, acts of war, civil commotions, riots, strikes, lockouts, acts
            of the government in either its sovereign or contractual capacity,
            perturbation in telecommunications transmissions, inability to
            obtain suitable equipment or components, accident, fire, water
            damages, flood, earthquake, or other natural catastrophe.{" "}
          </p>
          <p>
            <strong>WAIVERS: </strong>No waiver of a breach, failure of any
            condition or any right or remedy in or granted under these Terms and
            Conditions will be effective unless it is in writing and signed by
            the party waiving the breach, failure, right, or remedy. No waiver
            of any breach, failure, right, or remedy will be deemed a waiver of
            any other breach, failure, right, or remedy, whether or not similar,
            nor will any waiver constitute a continuing waiver unless the
            writing so specifies.{" "}
          </p>
          <p>
            <strong>SEVERABILITY: </strong>Any provision of this Agreement that
            in any way contravenes the law of any state or country in which this
            Agreement is effective will, in that state or country, to the extent
            the law is contravened, be considered separable and inapplicable and
            will not affect any other provision or provisions of this Agreement.{" "}
          </p>
          <p>
            <strong>RELATIONSHIP OF THE PARTIES: </strong>WBAAL and Client agree
            that WBAAL is an independent contractor to Client and will not act
            as Client&rsquo;s employee, agent, representative or sponsor under
            the terms and conditions stated herein.{" "}
          </p>
          <p>
            <strong>SUCESSORS &amp; ASSIGNS: </strong>This Agreement will inure
            to the benefit of, and be binding on, the successors and assigns of
            WBAAL and the Client.{" "}
          </p>
          <p>
            <strong>PARAGRAPH HEADINGS: </strong>All paragraph headings are for
            convenience only and shall not be construed as a limitation of the
            scope of the particular sections to which they refer.
          </p>
          <p>
            <strong>ENTIRE AGREEMENT: </strong>These Terms and Conditions
            constitute the final, complete, and exclusive statement of the terms
            of the agreement between the parties and supersede all prior and
            contemporaneous understandings or agreements of the parties. No
            party is relying on any representation or warranty outside those
            expressly set forth in this Agreement.
          </p>
        </div>
        <RightColumn />
      </Layout>
    );
  }
}
